export enum ValidationError {
  INVALID_LENGTH_OF_NODES = 'Deben haber mínimo 3 nodos en el diagrama',
  NOT_CONNECTED_TO_END = 'Hay un rama del diagrma no conectado a un nodo tipo finalización',
  INVALID_NUMBER_OF_START_NODES = 'Numero incorrecto de nodo tipo inicio',
  NO_END_NODE = 'No existen nodos de tipo finalizacón',
  NO_PARENT_NODE = 'Hay nodos tipo evento que no tienen un nodo padre conectado',
  NO_CHILD_NODE = 'Hay nodos tipo evento que no tienen un nodo hijo conectado',
  DUPLICATE_NODE_IDS = 'Existen nodos duplicados',
  NO_COPY_IDS = 'Debe haber plantillas seleccionadas para el nodo {{nodoType}}',
  NO_TARGET_IDS = 'Debe seleccionar una campañas objetivo para el nodo {{nodoType}}',
  NO_TARGET_NODE = 'Debe seleccionar un nodo objetivo para el nodo {{nodoType}}',
  NO_STATUS = 'Debe seleccionar al menos un estado para el nodo {{nodoType}}',
  NO_SEGMENTS = 'Debe seleccionar al menos un segmento para el nodo {{nodoType}}',
  NO_MIN_AMOUNT = 'Debe añadir un importe mínimo para el nodo {{nodoType}}',
  EXCEEDED_PERCENTAGE = 'El porcentage debe mantener un valor entre 1 y 99',
  EXCEEDED_CADUCITY = 'La caducidad debe mantener un valor entre 1 y 90',
  EXCEEDED_INSTALMENTS = 'El número de cuotas debe mantener un valor entre 2 y 11',
  NO_EXPIRATION = 'Debe añadir un día de caducidad para el nodo {{nodoType}}',
  NO_PART_DAY = 'Debe añadir días de espera para el nodo {{nodoType}}',
  NO_DAY_OF_MONTH = 'Debe seleccionar día del mes para el nodo {{nodoType}}',
  NO_WAIT_DAYS = 'Debe añadir días de espera para el nodo {{nodoType}}',
  NO_BEFORE_AFTER = 'Debe seleccionar antes o después del vencimiento para el nodo {{nodoType}}',
  ERROR_DAY_BEFORE_AFTER = 'Hay error en los días configurados en relación al campo Antes o Despúes, puede que el valor sea incorrecto para el nodo {{nodoType}}',
  NO_DAY_OF_WEEK = 'Debe seleccionar un día de la semana para el nodo {{nodoType}}',
  NO_CASE_CONDITIONAL = 'Debe haber seleccionado y configurado un caso falso o verdadero para el nodo {{nodoType}}',
  NO_VOICE_ID = 'Debe añadir un código de voz para el nodo {{nodoType}}',
  NO_PERCENTAGE = 'Debe añadir un porcentage al nodo tipo {{nodoType}} entre 0 y 100',
}

export function getValidationErrorMessage(
  error: ValidationError,
  params?: { [key: string]: string },
): string {
  let message = error as string;

  if (params) {
    Object.keys(params).forEach((key) => {
      message = message.replace(`{{${key}}}`, params[key]);
    });
  }

  return message;
}
