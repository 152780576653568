/**
 * An array of gender options allowed by the backend. Each option has a `value` and a `label` property.
 * The `value` property is the string sent to the backend, while the `label` property is the human-readable text.
 *
 * @type {Array<{ value: string, label: string }>}
 *
 * @example
 * new FormDropdown({
        key: 'gender',
        type: 'dropdown',
        required: false,
        label: 'Gender',
        multi: false,
        options: genderOptions,
        value: this.customer.gender,
        ...etc
      }),
 */
export const genderOptions = [
  { value: 'male', label: 'Hombre' },
  { value: 'female', label: 'Mujer' },
  { value: 'not_known', label: 'Prefiero no decirlo' },
  { value: 'non_binary', label: 'No binario' },
];
