import { Component, Input } from '@angular/core';
import { localeList } from '@shared/helpers';

@Component({
  selector: 'app-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.scss'],
})
export class FlagsComponent {
  @Input() countryCode: string = 'es';

  getCountryLabel(): string {
    const locale = localeList.find((item: any) => item.value === this.countryCode);
    return locale ? locale.label : 'Unknown';
  }
}
