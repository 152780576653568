import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import {
  ConditionalConfig,
  ExperimentConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import {
  getValidationErrorMessage,
  ValidationError,
} from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

export class ConditionalExperimentNode extends NodeClass {
  constructor(id: string, position: { x: number; y: number }, config: ExperimentConfig) {
    super(id, NodeType.Experiment, position, config);
  }

  isEmptyConfig(): boolean {
    return this.config['percentage'] == undefined;
  }

  addChild(node: string): void {
    if (this.childrenNodes.length === 0 || 1) this.childrenNodes.push(node);
    else console.warn('TENEMOS CHILD');
  }

  addParent(node: string): void {
    if (this.parentNodes.length === 0) this.parentNodes.push(node);
    else console.warn('TENEMOS PARENT');
  }

  initNodeConfig() {
    (this.config as ExperimentConfig).percentage = 1;
    (this.config as ExperimentConfig).cases = { false: null, true: null };
  }

  validate(): NodeValidationError[] {
    const errors: NodeValidationError[] = [];
    if (
      this.config['percentage'] === '' ||
      Number(this.config['percentage']) < 0 ||
      Number(this.config['percentage']) > 100
    ) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_PERCENTAGE, {
        nodoType: 'Experimento',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    if (this.config['cases'].false == null || this.config['cases'].true == null) {
      const errorMessage = getValidationErrorMessage(ValidationError.NO_CASE_CONDITIONAL, {
        nodoType: 'Experimento',
      });
      errors.push({ nodeId: this.id, errorMessage });
    }

    return errors;
  }
}
