import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { GET_CURRENT_USER, UPDATE_CURRENT_USER } from './graphQL/currentUser.GraphQL';
import { AuthService } from '@auth0/auth0-angular';
import { CollectorService } from './collector/collector.service';
import { PermissionType, UserModel } from '@admin/modules/users/models/user.model';
import { CollectorModel } from '@admin/modules/collectors/model/collectors.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  public currentUser: UserModel = {} as UserModel;
  collectorsList: CollectorModel[] = [];
  tenantGlobalService: string = '';
  constructor(
    private apollo: Apollo,
    private authService: AuthService,
    private collectorsService: CollectorService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(async (params) => {
      const tenantParam = params['tenant'];
      this.tenantGlobalService = params['tenant'];
      if (
        this.currentUser?.permissions?.some((p) => p === PermissionType.FULL_ACCESS) &&
        tenantParam &&
        tenantParam !== this.currentUser.tenantId
      ) {
        // Detected change in 'tenant' param, update the currentUser
        await this.setCurrentUser(true, tenantParam);
      }
    });
  }

  async setCurrentUser(fullUser?: boolean, tenantId?: string): Promise<void> {
    const user: any = await new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.authService.user$.subscribe((res) => {
        if (res) {
          resolve(res);
        } else {
          reject();
        }
      });
    });

    const token = user.sub.replace('auth0|', '');

    this.currentUser = await this.getCurrentUser(token);

    if (fullUser) {
      this.currentUser = { ...user, ...this.currentUser };
    }

    if (this.allowAccessPermission([PermissionType.FULL_ACCESS])) {
      this.currentUser.tenantId = tenantId ?? this.tenantGlobalService ?? this.currentUser.tenantId;
      await this.collectorsService
        .getCollectorlistByTenant(tenantId ?? this.currentUser.tenantId)
        .then((res: any) => {
          this.collectorsList = res.searchCollectors;
          this.currentUser.collectorIds = this.collectorsList.map((c: CollectorModel) => c.id);
        });
    }
  }

  public getCurrentUser(token: string): Promise<any> {
    return new Promise<UserModel>((resolve, reject) => {
      this.apollo
        .query({
          query: GET_CURRENT_USER,
          variables: {
            id: token,
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            resolve(res.data.userIdentify);
          }
        });
    });
  }

  public updateCurrentUser(toEditUser: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apollo
        .query({
          query: UPDATE_CURRENT_USER,
          variables: {
            input: {
              ...toEditUser,
            },
          },
        })
        .subscribe((res: any) => {
          if (res.errors?.length) {
            reject(res);
          } else {
            this.setCurrentUser(res.data.updateUser);
            resolve(res.data.userIdentify);
          }
        });
    });
  }

  public isPermissionAuthorized(permissions: string[]): boolean {
    const user = this.currentUser;
    if (user) {
      return !(
        permissions &&
        !this.isIndexOfArray(user.permissions, permissions) &&
        user.permissions[0] !== PermissionType.FULL_ACCESS
      );
    }
    return false;
  }

  allowAccessPermission = (permissions: PermissionType[]) => {
    const user = this.currentUser;
    return permissions.some((permission) => user.permissions.includes(permission));
  };

  private isIndexOfArray(array: any, searchArray: any): boolean {
    const isIndexed = searchArray.find((searchElement: any) => {
      return array.indexOf(searchElement) !== -1;
    });
    return !!isIndexed;
  }
}
