import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { TenantGuard } from '@guards/tenant.guard';

import { AppLayoutComponent } from '@layout/app.layout.component';
import { NewFeatureSoonComponent } from '@shared/components/new-feature-soon/new-feature-soon.component';
import { PermissionGuard } from '@guards/permision.guard';
import { PermissionType } from '@admin/modules/users/models/user.model';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'top',
  scrollOffset: [0, 0],
};

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, TenantGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        data: {
          breadcrumb: 'Panel de control',
        },
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'customer-journey',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Customer journey',
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('./modules/customer-journey/customer-journey.module').then(
            (m) => m.CustomerJourneyModule,
          ),
      },
      {
        path: 'behavioral-pill',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Píldora conductual',
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('./modules/behavioral-pill/behavioral-pill.module').then(
            (m) => m.BehavioralPillModule,
          ),
      },

      {
        path: 'tenants',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () => import('./modules/tenants/tenants.module').then((m) => m.TenantsModule),
      },
      {
        path: 'profile',

        canActivate: [PermissionGuard],
        data: { breadcrumb: 'Perfil', permissions: [PermissionType.USER_READ] },
        loadChildren: () =>
          import('./modules/user-profile/user-profile.module').then((m) => m.UserProfileModule),
      },
      {
        path: 'operations',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Operaciones',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.OPERATION_READ],
        },
        loadChildren: () =>
          import('./modules/operations/operations.module').then((m) => m.OperationsModule),
      },
      {
        path: 'customers',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Clientes',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.CUSTOMER_READ],
        },
        loadChildren: () =>
          import('./modules/customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        path: 'conversations',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Conversaciones',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.CONVERSATIONS],
        },
        loadChildren: () =>
          import('./modules/conversations/chat.app.module').then((m) => m.ChatAppModule),
      },
      {
        path: 'calendar',

        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Calendario',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.CAMPAIGN_CALENDAR_QUERIES],
        },
        loadChildren: () =>
          import('./modules/calendar/calendar.app.module').then((m) => m.CalendarAppModule),
      },
      {
        path: 'reports',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS, PermissionType.REPORTS],
        },
        loadChildren: () => import('./modules/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'copy',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Plantillas',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.COPY_READ],
        },
        loadChildren: () => import('./modules/copies/copies.module').then((m) => m.CopiesModule),
      },
      {
        path: 'campaigns',
        data: {
          breadcrumb: 'Campañas',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.WORKFLOW_READ],
        },
        loadChildren: () =>
          import('./modules/campaigns/campaigns.module').then((m) => m.CampaignsModule),
      },
      {
        path: 'listings',

        data: {
          breadcrumb: 'Listados',
        },
        loadChildren: () =>
          import('./modules/listings/listings.module').then((m) => m.ListingsModule),
      },
      {
        path: 'instances',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'Expedientes',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.CUSTOMER_CASE_READ],
        },
        loadChildren: () =>
          import(
            './modules/customer-case-campaings-instances/customer-case-campaings-instances.module'
          ).then((m) => m.CustomerCaseCampaingsInstancesModule),
      },
      {
        path: 'import',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'DATA.MODULES.IMPORT.TITLE',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.IMPORT_READ],
        },
        loadChildren: () =>
          import('./modules/import-line/import-line.module').then((m) => m.ImportLineModule),
      },
      {
        path: 'payment-agreement',
        canActivate: [PermissionGuard],
        data: {
          breadcrumb: 'DATA.MODULES.PAYMENT_AGREEMENT.TITLE',
          permissions: [PermissionType.FULL_ACCESS, PermissionType.ADMINISTRATION],
        },
        loadChildren: () =>
          import('./modules/payment-agreement/payment-agreement.module').then(
            (m) => m.PaymentAgreementModule,
          ),
      },
      {
        path: 'segments',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS, PermissionType.SEGMENT_READ],
        },
        loadChildren: () =>
          import('./modules/segments/segments.module').then((m) => m.SegmentsModule),
      },

      //PAY 2 ADMINISTRATION PANELS
      {
        path: 'brands',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/brands/brands.module').then((m) => m.BrandsModule),
      },
      {
        path: 'users',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () => import('@admin/modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'portfolios',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/portfolios/portfolios.module').then((m) => m.PortfoliosModule),
      },
      {
        path: 'tax-entity',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/tax-entity/tax-entity.module').then((m) => m.TaxEntityModule),
      },
      {
        path: 'services',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/services/service.module').then((m) => m.ServiceModule),
      },
      {
        path: 'partial-payment-configs',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/partial-payment-configs/partial-payment-configs.module').then(
            (m) => m.partialPaymentConfigsModule,
          ),
      },
      {
        path: 'event-hooks',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/event-hook/event-hook.module').then((m) => m.EventHooksModule),
      },
      {
        path: 'phases',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/phases/phases.module').then((m) => m.PhasesModule),
      },
      {
        path: 'regional-configs',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/regional-configs/regional-configs.module').then(
            (m) => m.RegionalConfigsModule,
          ),
      },
      {
        path: 'payment-methods',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/payment-method/payment-method.module').then(
            (m) => m.PaymentMethodModule,
          ),
      },
      {
        path: 'collectors',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/collectors/collectors.module').then((m) => m.CollectorsModule),
      },
      {
        path: 'channel-configs',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/channel-configs/channel-configs.module').then(
            (m) => m.ChannelConfigsModule,
          ),
      },
      {
        path: 'feedback-configs',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
        },
        loadChildren: () =>
          import('@admin/modules/feedback-configs/feedback-configs.module').then(
            (m) => m.FeedbackConfigsModule,
          ),
      },
      {
        path: 'business-insights',
        canActivate: [PermissionGuard],
        data: {
          permissions: [PermissionType.FULL_ACCESS],
          breadcrumb: 'Business Insights',
        },
        loadChildren: () =>
          import('./modules/bussiness-insights/business-insights.modules').then(
            (m) => m.BusinessInsightsModule,
          ),
      },

      //UTILS
      {
        path: 'new-feature/:featureName',
        component: NewFeatureSoonComponent,
      },
      {
        path: 'notfound',
        loadChildren: () => import('./core/notfound/notfound.module').then((m) => m.NotfoundModule),
      },

      { path: '**', redirectTo: '/notfound' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
