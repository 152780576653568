import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { StartNode } from '@modules/campaigns/Domain/Nodes/StartNode';
import { WaitDaysNode } from '@modules/campaigns/Domain/Nodes/WaitDaysNode';
import {
  AssaignCampaignConfig,
  ConditionalConfig,
  ConditionalSegmentConfig,
  EmailCertifiedConfig,
  EmailConfig,
  ExperimentConfig,
  MakeOutBoundCallConfig,
  OfferPartialPaymentConfig,
  OfferPaymentAgreementConfig,
  SMSCertifiedConfig,
  SMSConfig,
  SuperEmailCertifiedConfig,
  SuperEmailConfig,
  SuperSMSCertifiedConfig,
  SuperSMSConfig,
  WaitDayOfMonthConfig,
  WaitDaysConfig,
  WaitDaysForDueDateConfig,
  WaitDaysOfWeekConfig,
} from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { WaitDaysOFWeekNode } from '@modules/campaigns/Domain/Nodes/WaitDaysOfWeekNode';
import { WaitDaysForDueDateNode } from '@modules/campaigns/Domain/Nodes/WaitDaysOfExpirationNode';
import { WaitDayOfMonthNode } from '@modules/campaigns/Domain/Nodes/WaitDayOfMonthNode';
import { SMSNode } from '@modules/campaigns/Domain/Nodes/SMSNode';
import { EndNode } from '@modules/campaigns/Domain/Nodes/EndNode';
import { EmailNode } from '@modules/campaigns/Domain/Nodes/EmailNode';
import { ConditionalNode } from '@modules/campaigns/Domain/Nodes/ConditionalNode';
import { EmailCertifiedNode } from '@modules/campaigns/Domain/Nodes/EmailCertifiedNode';
import { SMSCertifiedNode } from '@modules/campaigns/Domain/Nodes/SMSCertifiedNode';
import { SuperSMSNode } from '@modules/campaigns/Domain/Nodes/SuperSMSNode';
import { SuperSMSCertifiedNode } from '@modules/campaigns/Domain/Nodes/SuperSMSCertifiedNode';
import { SuperEmailNode } from '@modules/campaigns/Domain/Nodes/SuperEmailNode';
import { SuperEmailCertifiedNode } from '@modules/campaigns/Domain/Nodes/SuperEmailCertifiedNode';
import { AssaignCampaignNode } from '@modules/campaigns/Domain/Nodes/AssignCampaignNode';
import { MultiConditional } from '@modules/campaigns/Domain/Nodes/MultiConditional';
import { OfferPartialPaymentNode } from '@modules/campaigns/Domain/Nodes/OfferPartialPayment';
import { OfferPaymentAgreementNode } from '@modules/campaigns/Domain/Nodes/OfferPaymentAgreement';
import { ConditionalSegmentNode } from '@modules/campaigns/Domain/Nodes/ConditionalNodeSegment';
import { MakeOutBoundCallNode } from '@modules/campaigns/Domain/Nodes/MakeOutBoundCall';
import { ExperimentNode } from '@shared/components/joint-js/config/extended.shapes';
import { ConditionalExperimentNode } from '@modules/campaigns/Domain/Nodes/ConditionalExperimentNode';

export class NodeFactory {
  static createNode(type: NodeType, x: number, y: number, id: string): NodeClass | null {
    switch (type) {
      case NodeType.Start:
        return new StartNode(id, { x, y });
      case NodeType.WaitDays:
        return new WaitDaysNode(id, { x, y }, {} as WaitDaysConfig);
      case NodeType.WaitDayOfWeek:
        return new WaitDaysOFWeekNode(id, { x, y }, {} as WaitDaysOfWeekConfig);
      case NodeType.WaitDaysForDueDate:
        return new WaitDaysForDueDateNode(id, { x, y }, {} as WaitDaysForDueDateConfig);
      case NodeType.WaitDayOfMonth:
        return new WaitDayOfMonthNode(id, { x, y }, {} as WaitDayOfMonthConfig);
      case NodeType.SendSMS:
        return new SMSNode(id, { x, y }, {} as SMSConfig);
      case NodeType.SendCertifiedSMS:
        return new SMSCertifiedNode(id, { x, y }, {} as SMSCertifiedConfig);
      case NodeType.SendEmail:
        return new EmailNode(id, { x, y }, {} as EmailConfig);
      case NodeType.SendCertifiedEmail:
        return new EmailCertifiedNode(id, { x, y }, {} as EmailCertifiedConfig);
      case NodeType.SuperSendSMS:
        return new SuperSMSNode(id, { x, y }, {} as SuperSMSConfig);
      case NodeType.SuperSendCertifiedSMS:
        return new SuperSMSCertifiedNode(id, { x, y }, {} as SuperSMSCertifiedConfig);
      case NodeType.SuperSendEmail:
        return new SuperEmailNode(id, { x, y }, {} as SuperEmailConfig);
      case NodeType.SuperSendCertifiedEmail:
        return new SuperEmailCertifiedNode(id, { x, y }, {} as SuperEmailCertifiedConfig);
      case NodeType.AssignCampaign:
        return new AssaignCampaignNode(id, { x, y }, {} as AssaignCampaignConfig);
      case NodeType.Conditional:
        return new ConditionalNode(id, { x, y }, {} as ConditionalConfig);
      case NodeType.ConditionalSegments:
        return new ConditionalSegmentNode(id, { x, y }, {} as ConditionalSegmentConfig);
      case NodeType.Experiment:
        return new ConditionalExperimentNode(id, { x, y }, {} as ExperimentConfig);
      case NodeType.OfferPartialPayment:
        return new OfferPartialPaymentNode(id, { x, y }, {} as OfferPartialPaymentConfig);
      case NodeType.OfferPaymentAgreement:
        return new OfferPaymentAgreementNode(id, { x, y }, {} as OfferPaymentAgreementConfig);
      case NodeType.MakeOutBoundCall:
        return new MakeOutBoundCallNode(id, { x, y }, {} as MakeOutBoundCallConfig);
      case NodeType.End:
        return new EndNode(id, { x, y });
      case NodeType.Multi:
        return new MultiConditional(id, { x, y }, {});
      default:
        throw new Error(`Invalid node type`);
    }
  }

  static parseAndCreateNode(nodeData: any): NodeClass | null {
    const nodeType = NodeFactory.mapStringToNodeType(
      nodeData.parameters.name,
      nodeData.parameters.copyIds,
    );
    const position_x =
      nodeData.diagram && nodeData.diagram.coordinates && nodeData.diagram.coordinates?.x
        ? nodeData.diagram.coordinates.x
        : 0;
    const position_y =
      nodeData.diagram && nodeData.diagram.coordinates && nodeData.diagram.coordinates?.y
        ? nodeData.diagram.coordinates.y
        : 0;

    let newDNode = null;

    newDNode = NodeFactory.createNode(
      nodeType!,
      position_x,
      position_y,
      nodeData.nodeId.toString(),
    );

    if (!newDNode) {
      throw new Error(`Failed to create node for ID: ${nodeData.nodeId}`);
    }

    newDNode.orderNode = nodeData.position;
    newDNode!.childrenNodes = nodeData.childrenIds;

    if (newDNode) {
      switch (nodeType) {
        case NodeType.WaitDays:
          const parameters = nodeData.parameters as {
            name: string;
            waitDays: number;
            partDay: string;
          };
          const partDay = parameters.partDay;
          (newDNode.config as WaitDaysConfig).waitDays = parameters.waitDays;
          (newDNode.config as WaitDaysConfig).partDay = partDay;
          break;
        case NodeType.WaitDayOfWeek:
          if (
            nodeData.parameters.partDay === undefined ||
            nodeData.parameters.dayOfWeek === undefined
          ) {
            throw new Error(
              `Missing parameters for WaitDayOfWeek node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as WaitDaysOfWeekConfig).partDay = nodeData.parameters.partDay;
          (newDNode.config as WaitDaysOfWeekConfig).dayOfWeek = nodeData.parameters.dayOfWeek;
          break;
        case NodeType.WaitDaysForDueDate:
          if (
            nodeData.parameters.partDay === undefined ||
            nodeData.parameters.waitDays === undefined
          ) {
            throw new Error(
              `Missing parameters for WaitDaysForDueDate node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as WaitDaysForDueDateConfig).partDay = nodeData.parameters.partDay;
          (newDNode.config as WaitDaysForDueDateConfig).waitDays = nodeData.parameters.waitDays;
          (newDNode.config as WaitDaysForDueDateConfig).antesODespues = nodeData.parameters
            .beforeOrAfter
            ? nodeData.parameters.beforeOrAfter
            : 'beforeDueDate';
          break;
        case NodeType.WaitDayOfMonth:
          if (
            nodeData.parameters.partDay === undefined ||
            nodeData.parameters.dayOfMonth === undefined
          ) {
            throw new Error(
              `Missing parameters for WaitDaysOfMonth node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as WaitDayOfMonthConfig).partDay = nodeData.parameters.partDay;
          (newDNode.config as WaitDayOfMonthConfig).dayOfMonth = nodeData.parameters.dayOfMonth;
          break;
        case NodeType.SendSMS:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SendSMS node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as SMSConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as SMSConfig).super = false;
          (newDNode.config as SMSConfig).certified = false;
          (newDNode.config as SMSConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.SendCertifiedSMS:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SendCertifiedSendSMS node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as SMSCertifiedConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as SMSCertifiedConfig).super = false;
          (newDNode.config as SMSCertifiedConfig).certified = true;
          (newDNode.config as SMSCertifiedConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.SendEmail:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SendEmail node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as EmailConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as EmailConfig).super = false;
          (newDNode.config as EmailConfig).certified = false;
          (newDNode.config as EmailConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.SendCertifiedEmail:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SendCertifiedEmail node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as EmailCertifiedConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as EmailCertifiedConfig).super = false;
          (newDNode.config as EmailCertifiedConfig).certified = true;
          (newDNode.config as EmailCertifiedConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.SuperSendSMS:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SuperSendSmS node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as SuperSMSConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as SuperSMSConfig).super = true;
          (newDNode.config as SuperSMSConfig).certified = false;
          (newDNode.config as SuperSMSConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.SuperSendCertifiedSMS:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SuperSendCertifiedSms node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as SuperSMSCertifiedConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as SuperSMSCertifiedConfig).super = true;
          (newDNode.config as SuperSMSCertifiedConfig).certified = true;
          (newDNode.config as SuperSMSCertifiedConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.SuperSendEmail:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SuperSendEmail node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as SuperEmailConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as SuperEmailConfig).super = true;
          (newDNode.config as SuperEmailConfig).certified = false;
          (newDNode.config as SuperEmailConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.SuperSendCertifiedEmail:
          if (!nodeData.parameters.copyIds) {
            throw new Error(
              `Missing parameters for SuperSendCertifiedEmail node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as SuperEmailCertifiedConfig).copyIds = nodeData.parameters.copyIds;
          (newDNode.config as SuperEmailCertifiedConfig).super = true;
          (newDNode.config as SuperEmailCertifiedConfig).certified = true;
          (newDNode.config as SuperEmailCertifiedConfig).sendToAllContacts = Boolean(
            nodeData.parameters.sendToAllContacts,
          );
          break;
        case NodeType.AssignCampaign:
          if (!nodeData.parameters.targetCampaignId) {
            throw new Error(
              `Missing parameters for AssignCampaign node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as AssaignCampaignConfig).targetCampaignId =
            nodeData.parameters.targetCampaignId;
          break;
        case NodeType.OfferPaymentAgreement:
          if (!nodeData.parameters.expirationDays || !nodeData.parameters.numberOfInstalments) {
            throw new Error(
              `Missing parameters for OfferPaymentAgreement node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as OfferPaymentAgreementConfig).expirationDays =
            nodeData.parameters.expirationDays;
          (newDNode.config as OfferPaymentAgreementConfig).numberOfInstalments =
            nodeData.parameters.numberOfInstalments;
          break;
        case NodeType.Conditional:
          if (
            nodeData.parameters.targetNodeId === undefined ||
            nodeData.parameters.statuses === undefined
          ) {
            throw new Error(
              `Missing parameters for Coditional node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as ConditionalConfig).targetNodeId = nodeData.parameters.targetNodeId;
          (newDNode.config as ConditionalConfig).statuses = nodeData.parameters.statuses;

          if (!(newDNode.config as ConditionalConfig).cases) {
            (newDNode.config as ConditionalConfig).cases = { false: null, true: null };
          }

          (newDNode.config as ConditionalConfig).cases.false = nodeData.parameters.cases.false;
          (newDNode.config as ConditionalConfig).cases.true = nodeData.parameters.cases.true;
          break;
        case NodeType.Experiment:
          if (nodeData.parameters.percentage === undefined) {
            throw new Error(
              `Missing parameters for Experiment node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as ExperimentConfig).percentage = Number(nodeData.parameters.percentage);

          if (!(newDNode.config as ExperimentConfig).cases) {
            (newDNode.config as ExperimentConfig).cases = { false: null, true: null };
          }

          (newDNode.config as ExperimentConfig).cases.false = nodeData.parameters.cases.false;
          (newDNode.config as ExperimentConfig).cases.true = nodeData.parameters.cases.true;
          break;
        case NodeType.ConditionalSegments:
          if (nodeData.parameters.segmentIds === undefined) {
            throw new Error(
              `Missing parameters for ConditionalSegment node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as ConditionalSegmentConfig).segmentIds = nodeData.parameters.segmentIds;

          if (!(newDNode.config as ConditionalSegmentConfig).cases) {
            (newDNode.config as ConditionalSegmentConfig).cases = { false: null, true: null };
          }

          (newDNode.config as ConditionalSegmentConfig).cases.false =
            nodeData.parameters.cases.false;
          (newDNode.config as ConditionalSegmentConfig).cases.true = nodeData.parameters.cases.true;
          break;
        case NodeType.OfferPartialPayment:
          if (nodeData.parameters.expirationDays === undefined) {
            throw new Error(
              `Missing parameters for Offer Partial Payment node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as OfferPartialPaymentConfig).expirationDays =
            nodeData.parameters.expirationDays;
          (newDNode.config as OfferPartialPaymentConfig).minimumPercentageAmount =
            nodeData.parameters.minimumPercentageAmount;

          if (!(newDNode.config as OfferPartialPaymentConfig).minimumAmount) {
            (newDNode.config as OfferPartialPaymentConfig).minimumAmount = {
              amount: null,
              currency: null,
            };
          }

          (newDNode.config as OfferPartialPaymentConfig).minimumAmount.amount = (
            nodeData.parameters.minimumAmount.amount / 100
          ).toString();
          (newDNode.config as OfferPartialPaymentConfig).minimumAmount.currency =
            nodeData.parameters.minimumAmount.currency;
          break;
        case NodeType.MakeOutBoundCall:
          if (
            nodeData.parameters.voiceId === undefined ||
            nodeData.parameters.copyId === undefined
          ) {
            throw new Error(
              `Missing parameters for Make Out Bound Call node: ${JSON.stringify(nodeData.parameters)}`,
            );
          }
          (newDNode.config as MakeOutBoundCallConfig).voiceId = nodeData.parameters.voiceId;
          (newDNode.config as MakeOutBoundCallConfig).copyId = nodeData.parameters.copyId;

          break;
        default:
          break;
      }
    }

    return newDNode;
  }

  static mapStringToNodeType(type: string, copyIds?: string[]): NodeType | undefined | null {
    switch (type) {
      case 'start':
        return NodeType.Start;
      case 'waitDayOfMonth':
        return NodeType.WaitDayOfMonth;
      case 'waitDays':
        return NodeType.WaitDays;
      case 'waitDaysForDueDate':
        return NodeType.WaitDaysForDueDate;
      case 'waitDayOfWeek':
        return NodeType.WaitDayOfWeek;
      case 'paylinkNotificationStatus':
        return NodeType.Conditional;
      case 'sendSMS':
        if (copyIds && copyIds.length > 1) {
          return NodeType.SuperSendSMS;
        }
        return NodeType.SendSMS;
      case 'sendEmail':
        if (copyIds && copyIds.length > 1) {
          return NodeType.SuperSendEmail;
        }
        return NodeType.SendEmail;
      case 'sendCertifiedSMS':
        if (copyIds && copyIds.length > 1) {
          return NodeType.SuperSendCertifiedSMS;
        }
        return NodeType.SendCertifiedSMS;
      case 'sendCertifiedEmail':
        if (copyIds && copyIds.length > 1) {
          return NodeType.SuperSendCertifiedEmail;
        }
        return NodeType.SendCertifiedEmail;
      case 'superSendSMS':
        return NodeType.SuperSendSMS;
      case 'superSendCertifiedSMS':
        return NodeType.SuperSendCertifiedSMS;
      case 'superSendEmail':
        return NodeType.SuperSendEmail;
      case 'superSendCertifiedEmail':
        return NodeType.SuperSendCertifiedEmail;
      case 'assignCampaign':
        return NodeType.AssignCampaign;
      case 'conditional':
        return NodeType.Conditional;
      case 'customerCaseSegments':
        return NodeType.ConditionalSegments;
      case 'experiment':
        return NodeType.Experiment;
      case 'end':
        return NodeType.End;
      case 'offerPartialPayment':
        return NodeType.OfferPartialPayment;
      case 'offerPaymentAgreement':
        return NodeType.OfferPaymentAgreement;
      case 'multiconditional':
        return NodeType.Multi;
      case 'makeOutboundCall':
        return NodeType.MakeOutBoundCall;
      default:
        return null;
    }
  }
}
