export const allowLangs = [
  { value: 'es', label: 'Español' },
  { value: 'en', label: 'Inglés' },
  { value: 'ca', label: 'Catalán' },
];

export const localeList: any = [
  {
    code: 'es',
    value: 'es',
    label: 'Español',
  },
  {
    code: 'mx',
    value: 'es_MX',
    label: 'Español (México)',
  },
  {
    code: 'gb',
    value: 'en',
    label: 'Inglés',
  },
  {
    code: 'cat',
    value: 'ca',
    label: 'Catalán',
  },
  {
    code: 'ca_ES_VALENCIA',
    value: 'ca_ES_VALENCIA',
    label: 'Valenciano',
  },
  {
    code: 'fr',
    value: 'fr',
    label: 'Francés',
  },
  {
    code: 'nl',
    value: 'nl',
    label: 'Neerlandés',
  },
  {
    code: 'pt',
    value: 'pt',
    label: 'Portugués',
  },
  {
    code: 'de',
    value: 'de',
    label: 'Alemán',
  },
  {
    code: 'it',
    value: 'it',
    label: 'Italiano',
  },
];
