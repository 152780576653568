export enum NodeType {
  Start = 'start',
  WaitDayOfMonth = 'waitDayOfMonth',
  WaitDays = 'waitDays',
  WaitDaysForDueDate = 'waitDaysForDueDate',
  WaitDayOfWeek = 'waitDayOfWeek',
  PaylinkNotificationStatus = 'paylinkNotificationStatus',
  SendSMS = 'sendSMS',
  SendEmail = 'sendEmail',
  SendCertifiedSMS = 'sendCertifiedSMS',
  SendCertifiedEmail = 'sendCertifiedEmail',
  SuperSendSMS = 'superSendSMS',
  SuperSendCertifiedSMS = 'superSendCertifiedSMS',
  SuperSendEmail = 'superSendEmail',
  SuperSendCertifiedEmail = 'superSendCertifiedEmail',
  AssignCampaign = 'assignCampaign',
  Conditional = 'paylinkNotificationStatus',
  ConditionalSegments = 'customerCaseSegments',
  Experiment = 'experiment',
  End = 'end',
  Multi = 'multiconditional',
  OfferPartialPayment = 'offerPartialPayment',
  OfferPaymentAgreement = 'offerPaymentAgreement',
  MakeOutBoundCall = 'makeOutboundCall',
}
