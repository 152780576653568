import { WorkflowStatus } from '@modules/campaigns/Domain/Enums/WorkflowStatus';
import { IWorkflow } from '@modules/campaigns/Domain/Interfaces/IWorkflow';
import { WorkflowType } from '@modules/campaigns/Domain/Enums/WorkflowType';
import { NodeClass } from '@modules/campaigns/Domain/NodeClass';
import { ValidationError } from '@modules/campaigns/Domain/Enums/ValidationErrors';
import { NodeType } from '@modules/campaigns/Domain/Enums/NodeType';
import { IWorkflowValidator } from '@modules/campaigns/Domain/Interfaces/IWorkflowValidator';
import { ValidatorFactory } from '@modules/campaigns/Domain/Validators/ValidatorFactory';
import { StartNode } from '@modules/campaigns/Domain/Nodes/StartNode';
import { EndNode } from '@modules/campaigns/Domain/Nodes/EndNode';
import {
  InvalidNodeTypeError,
  NodeNotFoundError,
} from '@modules/campaigns/Domain/Errors/WorkflowErrors';
import { hascopyIds } from '@modules/campaigns/Domain/Factories/HasNodeConfig';
import { ConditionalConfig } from '@modules/campaigns/Domain/Interfaces/INodeConfig';
import { NodeValidationError } from '@modules/campaigns/Domain/Interfaces/NodeValidationError';

const MARGIN_X = 150;
const MARGIN_Y = 150;
const MIN_MARGIN = 25;

export class WorkflowClass implements IWorkflow {
  id: string;
  name: string;
  status: WorkflowStatus;
  type: WorkflowType;
  nodes: NodeClass[] = [];
  private validators: IWorkflowValidator[];

  constructor(id: string, name: string, type: WorkflowType) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.status = WorkflowStatus.DRAFT;
    this.validators = ValidatorFactory.getValidators();
  }

  create(): IWorkflow {
    return this;
  }

  getNode(nodeId: string): NodeClass | undefined {
    return this.nodes.find((node) => node.id === nodeId);
  }

  getNextNode(nodeId: string): NodeClass | undefined {
    const node = this.getNode(nodeId);
    if (node) {
      const childNode = this.findNode(node?.childrenNodes[0]);
      if (childNode) {
        return childNode;
      } else {
        throw new NodeNotFoundError(nodeId);
      }
    } else {
      throw new NodeNotFoundError(nodeId);
    }
  }

  findNode(nodeId: string): NodeClass | undefined {
    return this.getNode(nodeId);
  }

  addNode(node: NodeClass): void {
    this.nodes.push(node);
  }

  removeNode(nodeId: string): void {
    const node = this.getNode(nodeId);

    if (!node) {
      throw new NodeNotFoundError(nodeId);
    }

    if (node.type === NodeType.Start) {
      throw new InvalidNodeTypeError('Cannot remove start node');
    }

    // Step 1: Check if the node is a target node for any conditional nodes
    this.nodes.forEach((n) => {
      if (n.config && (n.config as ConditionalConfig).targetNodeId === nodeId) {
        (n.config as ConditionalConfig).targetNodeId = null;
      }
    });

    // Step 2: Update parent and child relationships
    node.childrenNodes.forEach((childNodeId) => this.getNode(childNodeId)?.removeParent(node));
    node.parentNodes.forEach((parentNodeId) => this.getNode(parentNodeId)?.removeChild(node));

    // Step 3: Remove the node from the nodes array
    this.nodes = this.nodes.filter((n) => n.id !== nodeId);
  }

  updateNode(node: NodeClass): void {
    const index = this.nodes.findIndex((n) => n.id === node.id);
    if (index === -1) {
      throw new NodeNotFoundError(node.id);
    }
    this.nodes[index] = node;
  }

  validate(): string[] {
    return this.validators
      .filter((validator) => validator.shouldValidate(this))
      .flatMap((validator) => validator.validate(this));
  }

  validateNodes(): NodeValidationError[] {
    return this.nodes.flatMap((node) => node.validate());
  }

  isDraft(): boolean {
    return this.status === WorkflowStatus.DRAFT;
  }

  initWorkflow(startNodeId: string, endNodeId: string): void {
    try {
      const startNode = new StartNode(startNodeId, { x: 200, y: 0 });
      const endNode = new EndNode(endNodeId, { x: 200, y: 100 });

      startNode.addChild(endNode.id);
      endNode.addParent(startNode.id);

      this.addNode(startNode);
      this.addNode(endNode);
    } catch (e) {
      console.log(e);
    }
  }

  linkNodes(parentNodeId: string, childNodeId: string) {
    const parentNode = this.findNode(parentNodeId);
    const childNode = this.findNode(childNodeId);

    if (!parentNode) {
      throw new NodeNotFoundError(parentNodeId);
    }
    if (!childNode) {
      throw new NodeNotFoundError(childNodeId);
    }

    parentNode.addChild(childNode.id);
    childNode.addParent(parentNode.id);
  }

  areNodesLinked(parentNodeId: string, childNodeId: string): boolean {
    const parentNode = this.findNode(parentNodeId);
    const childNode = this.findNode(childNodeId);

    if (!parentNode || !childNode) {
      throw new NodeNotFoundError(childNodeId);
    }

    return (
      parentNode.childrenNodes.includes(childNode.id) &&
      childNode.parentNodes.includes(parentNode.id)
    );
  }

  unlinkNodes(parentNodeId: string, childNodeId: string) {
    const parentNode = this.findNode(parentNodeId);
    const childNode = this.findNode(childNodeId);

    if (!parentNode) {
      throw new NodeNotFoundError(parentNodeId);
    }
    if (!childNode) {
      throw new NodeNotFoundError(childNodeId);
    }

    // Step 1: Remove childNode from parentNode's children and vice versa
    parentNode.removeChild(childNode);
    childNode.removeParent(parentNode);

    // Step 2: Check and update conditional nodes
    this.nodes.forEach((node) => {
      if (
        node.config &&
        (node.type === NodeType.Conditional ||
          node.type === NodeType.ConditionalSegments ||
          node.type === NodeType.Experiment)
      ) {
        const conditionalConfig = node.config as ConditionalConfig;
        const targetNodeId = conditionalConfig.targetNodeId;

        if (targetNodeId && (targetNodeId === childNode.id || targetNodeId === parentNode.id)) {
          if (!this.isPathExists(node.id, targetNodeId)) {
            conditionalConfig.targetNodeId = null;
          }
        }
      }
    });
  }

  isPathExists(startNodeId: string, endNodeId: string): boolean {
    const visited = new Set<string>();
    const stack = [startNodeId];

    while (stack.length > 0) {
      const nodeId = stack.pop();
      if (!nodeId || visited.has(nodeId)) {
        continue;
      }

      visited.add(nodeId);

      if (nodeId === endNodeId) {
        return true;
      }

      const node = this.findNode(nodeId);
      if (node) {
        stack.push(...node.childrenNodes);
      }
    }

    return false;
  }

  printWorkflowTree(): void {
    const startNode = this.nodes.find((node) => node.type === NodeType.Start);
    if (!startNode) {
      console.log('No start node found in the workflow.');
      return;
    }

    const traverse = (node: NodeClass, depth: number): void => {
      console.log(`${' '.repeat(depth * 2)}- ${node.id} (${node.type})`);
      node.childrenNodes.forEach((child) => traverse(this.findNode(child)!, depth + 1));
    };

    traverse(startNode, 0);
  }

  assignParentNodes(): void {
    this.nodes.forEach((node) => {
      let parentNode = node;
      let nodeChild = node.childrenNodes;
      nodeChild.forEach((child: any) => {
        const childNode = this.findNode(child);
        if (childNode) {
          childNode.addParent(parentNode.id);
        } else {
          throw new NodeNotFoundError(`Child node not found for ID: ${child}`);
        }
      });
    });
  }

  getParentNodes(node: NodeClass, parents: NodeClass[] = []): NodeClass[] {
    if (!node.parentNodes || node.parentNodes.length === 0) {
      return parents;
    }

    node.parentNodes.forEach((parentId) => {
      const parentNode = this.getNode(parentId);
      if (parentNode) {
        parents.push(parentNode);
        this.getParentNodes(parentNode, parents);
      }
    });

    return parents;
  }

  orderNodes(): void {
    this.removeDuplicateChildNodes();
    this.removeDuplicateParentNodes();

    const positionedNodes = new Set<string>();

    this.nodes = this.nodes.filter((node) => node instanceof NodeClass);

    const rootNodes = this.nodes.filter((node) => node.type === NodeType.Start);
    let xOffset = 0;

    if (rootNodes.length === 0) {
      throw new Error('No root nodes (start nodes) found in the workflow.');
    }

    rootNodes.forEach((rootNode, index) => {
      this.positionNode(rootNode, 0, xOffset, positionedNodes);
      xOffset += MARGIN_X + MIN_MARGIN;
    });

    this.positionUnconnectedNodes(positionedNodes, xOffset);
  }

  private positionNode(
    node: NodeClass,
    depth: number,
    xOffset: number,
    positionedNodes: Set<string>,
  ): void {
    // Set the position of the current node
    if (
      node.type === NodeType.Start ||
      node.type === NodeType.End ||
      node.type === NodeType.WaitDays ||
      node.type === NodeType.WaitDaysForDueDate ||
      node.type === NodeType.WaitDayOfMonth ||
      node.type === NodeType.WaitDayOfWeek ||
      node.type === NodeType.OfferPartialPayment ||
      node.type === NodeType.OfferPaymentAgreement
    ) {
      node.position = { x: xOffset + 10, y: depth * MARGIN_Y };
    } else if (
      node.type === NodeType.Conditional ||
      node.type === NodeType.ConditionalSegments ||
      node.type === NodeType.Experiment
    ) {
      node.position = { x: xOffset + 50, y: depth * MARGIN_Y };
    } else {
      node.position = { x: xOffset, y: depth * MARGIN_Y };
    }

    positionedNodes.add(node.id);

    // Calculate horizontal offsets for child nodes of a conditional node
    if (
      (node.type === NodeType.Conditional ||
        node.type === NodeType.ConditionalSegments ||
        node.type === NodeType.Experiment) &&
      node.childrenNodes.length === 2
    ) {
      const subConditionals = this.calculateSubsequentConditionalNodes(node.id) + 1;
      const leftOffset = xOffset - MARGIN_X * subConditionals;
      const rightOffset = xOffset + MARGIN_X * subConditionals;
      this.positionChildNodes(node, depth, leftOffset, rightOffset, positionedNodes);
    } else {
      let childOffset = xOffset;
      node.childrenNodes.forEach((childId) => {
        const childNode = this.findNode(childId);
        if (childNode) {
          const childDepth = depth + 1;
          this.positionNode(childNode, childDepth, childOffset, positionedNodes);
          childOffset += MARGIN_X; // Increment for next child
        }
      });
    }
  }

  private positionChildNodes(
    node: NodeClass,
    depth: number,
    leftOffset: number,
    rightOffset: number,
    positionedNodes: Set<string>,
  ): void {
    node.childrenNodes.forEach((childId) => {
      const childNode = this.findNode(childId);
      if (childNode) {
        const childDepth = depth + 1;
        if (node.config['cases'].false === childNode.id) {
          this.positionNode(childNode, childDepth, leftOffset, positionedNodes);
        } else if (node.config['cases'].true === childNode.id) {
          this.positionNode(childNode, childDepth, rightOffset, positionedNodes);
        }
      }
    });
  }

  calculateSubsequentConditionalNodes(nodeId: string): number {
    const visited: Set<string> = new Set();
    let count = 0;

    const dfs = (currentNodeId: string) => {
      if (visited.has(currentNodeId)) return;
      visited.add(currentNodeId);

      const currentNode = this.findNode(currentNodeId);
      if (
        currentNode &&
        (currentNode.type === NodeType.Conditional ||
          currentNode.type === NodeType.ConditionalSegments ||
          currentNode.type === NodeType.Experiment)
      ) {
        count++;
      }

      currentNode?.childrenNodes.forEach((childId) => {
        dfs(childId);
      });
    };

    dfs(nodeId);

    // Subtract one if the initial node is conditional to avoid counting it twice
    const initialNode = this.findNode(nodeId);
    if (
      initialNode &&
      (initialNode.type === NodeType.Conditional ||
        initialNode.type === NodeType.ConditionalSegments ||
        initialNode.type === NodeType.Experiment)
    ) {
      count--;
    }

    return count;
  }

  private positionUnconnectedNodes(positionedNodes: Set<string>, xOffset: number): void {
    let depth = 0;
    this.nodes.forEach((node) => {
      if (!positionedNodes.has(node.id)) {
        node.position = { x: xOffset, y: depth * (MARGIN_Y + MIN_MARGIN) };
        xOffset += MARGIN_X + MIN_MARGIN;
        if (xOffset % (2 * (MARGIN_X + MIN_MARGIN)) === 0) {
          xOffset = 0;
          depth += 1;
        }
      }
    });
  }

  removeDuplicateParentNodes(): void {
    //VERIFICATIONS
    this.nodes.forEach((node) => {
      if (node.parentNodes && node.parentNodes.length > 1) {
        const uniqueParents = Array.from(new Set(node.parentNodes));
        if (uniqueParents.length < node.parentNodes.length) {
          node.parentNodes = uniqueParents;
        }
      }
    });
  }
  removeDuplicateChildNodes(): void {
    //VERIFICATIONS
    this.nodes.forEach((node) => {
      if (node.childrenNodes && node.childrenNodes.length > 1) {
        const uniqueChilds = Array.from(new Set(node.childrenNodes));
        if (uniqueChilds.length < node.childrenNodes.length) {
          node.childrenNodes = uniqueChilds;
        }
      }
    });
  }

  removeOrphanNodes(): void {
    this.nodes = this.nodes.filter(
      (node) =>
        node.type === NodeType.Start ||
        node.parentNodes.length > 0 ||
        node.childrenNodes.length > 0,
    );
  }

  getParentNodesWithCopyIdsInBranch(node: NodeClass): NodeClass[] {
    if (
      node.type !== NodeType.Conditional &&
      node.type !== NodeType.ConditionalSegments &&
      node.type !== NodeType.Experiment
    ) {
      throw new Error('The provided node is not a conditional node');
    }

    const parentNodes: NodeClass[] = [];

    const findParents = (currentNode: NodeClass) => {
      for (const parentId of currentNode.parentNodes) {
        const parentNode = this.getNode(parentId);
        if (parentNode) {
          if (hascopyIds(parentNode.config)) {
            parentNodes.push(parentNode);
          }
          findParents(parentNode);
        }
      }
    };

    findParents(node);
    return parentNodes;
  }
}
